import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
import fr from "./translations/fr.json"
import de from "./translations/de.json";
import it from "./translations/it.json";

const resources = {
  en,
  fr,
  de,
  it,
};

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        keySeparator: '.',
        resources,
        defaultNS: "common",
        fallbackLng: "en",
    });